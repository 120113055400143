import React from "react";
import FullScreenToggle from "../FullScreenToggle/FullScreenToggle";
import LanguageToggle from "../Language/Language";
import IFrame from "../IFrame/iframe";
import AudioRecorder from "../AudioRecorder/AudioRecorder";
const Landscape = ({
  styles,
  videoRef,
  videoSource,
  getLanguage,
  handleSetLanguage,
  handleSetVideoSource,
  token,
  webviewURL,
}) => {
  return (
    <div style={styles.container}>
      <style>
        {`
    @keyframes grow {
      0%, 100% {
        transform: scaleY(0.3); /* 初始和結束時縮小 */
      }
      50% {
        transform: scaleY(1); /* 中間時放大 */
      }
    }
  `}
      </style>
      <style>
        {`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `}
      </style>

      <div style={styles.videoContainer}>
        <div style={styles.placeholder}>
          <img
            src="idle.jpg"
            alt="Loading..."
            style={styles.placeholderImage}
          />
        </div>
        <video
          ref={videoRef}
          style={styles.video}
          loop
          autoPlay
          muted
          playsInline
          webkit-playsinline="true"
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={styles.overlay}></div> {/* 透明覆盖层 */}
        <FullScreenToggle styles={styles} />
        <LanguageToggle
          styles={styles}
          language={getLanguage}
          setLanguage={handleSetLanguage}
          webviewURL={webviewURL}
        />
        <AudioRecorder
          styles={styles}
          language={getLanguage}
          setVideoSource={handleSetVideoSource}
          token={token}
        />
      </div>
      <IFrame styles={styles} token={token} webviewURL={webviewURL} />
    </div>
  );
};

export default Landscape;
