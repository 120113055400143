import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";

function Login() {
  const [scanResult, setScanResult] = useState(null);
  const navigate = useNavigate(); // 使用 useNavigate 代替 useHistory

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      // 假设扫描结果就是登录用的token
      performLogin(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const performLogin = (token) => {
    // 在此处进行登录逻辑，比如发送请求到服务器验证token
    console.log("Logging in with token:", token);

    // 模拟登录成功，跳转到内页
    navigate("/dashboard"); // 使用 navigate 来替代 history.push
  };

  return (
    <div>
      <h1>QR Code Login</h1>
      {!scanResult ? (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%" }}
        />
      ) : (
        <p>Scanning result: {scanResult}</p>
      )}
    </div>
  );
}

export default Login;
