/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

const IFrame = ({ styles, token, webviewURL }) => {
  const iframeRef = useRef(null);
  const sendTokenToIframe = () => {
    const iframe = document.getElementById("myIframe");

    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: "__webview_setToken",
          source: "native-mock",
          data: token,
        },
        webviewURL
      );
      // iframe.contentWindow.postMessage(
      //   {
      //     type: "__webview_selectLang",
      //     source: "native-mock",
      //     data: "zh-TW",
      //   },
      //   "https://webview.ssos.dev.thebarkingdog.tw/"
      // );
    }
  };
  useEffect(() => {
    const iframe = document.getElementById("myIframe");

    const handleIframeLoad = () => {
      sendTokenToIframe();
    };

    if (iframe) {
      iframe.onload = handleIframeLoad;
    }

    return () => {
      if (iframe) {
        iframe.onload = null; // Cleanup the event listener
      }
    };
  }, [document.getElementById("myIframe")]);

  return (
    <div style={styles.iframeContainer}>
      <iframe
        id="myIframe"
        ref={iframeRef}
        src={webviewURL}
        title="Embedded Webpage"
        style={styles.iframe}
      ></iframe>
    </div>
  );
};

export default IFrame;
