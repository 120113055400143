import React from "react";

const LanguageToggle = ({ styles, language, setLanguage, webviewURL }) => {
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    const iframe = document.getElementById("myIframe");

    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: "__webview_selectLang",
          source: "native-mock",
          data: lang,
        },
        webviewURL
      );
    }
  };

  return (
    <div style={styles.languageSwitcher}>
      <button
        style={{
          ...styles.languageButton,
          ...(language === "zh-TW" ? styles.languageButtonActive : {}),
        }}
        onClick={() => handleLanguageChange("zh-TW")}
      >
        中文
      </button>
      <button
        style={{
          ...styles.languageButton,
          ...(language === "en-US" ? styles.languageButtonActive : {}),
        }}
        onClick={() => handleLanguageChange("en-US")}
      >
        English
      </button>
      {/* <button
  style={{
    ...styles.languageButton,
    ...(language === "jp-JP" ? styles.languageButtonActive : {}),
  }}
  onClick={() => handleLanguageChange("jp-JP")}
>
  日本語
</button> */}
    </div>
  );
};

export default LanguageToggle;
