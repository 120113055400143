/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { stylesold } from "./styles/styles";
import { landscapeStyles } from "./styles/landscape";
import Landscape from "./components/OrderPage/Landscape";
import Portrait from "./components/OrderPage/Portrait";
function MediaPage() {
  const token = process.env.REACT_APP_TOKEN;
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const [videoSource, setVideoSource] = useState("idle.mp4");
  const [language, setLanguage] = useState("zh-TW");
  const [webviewURL, setWebviewURL] = useState(
    process.env.REACT_APP_WEBVIEW_URL === "" ||
      process.env.REACT_APP_WEBVIEW_URL == null
      ? "https://webview.ssos.dev.thebarkingdog.tw/"
      : process.env.REACT_APP_WEBVIEW_URL
  );
  const [styles, setStyles] = useState(
    window.innerWidth > window.innerHeight ? landscapeStyles : stylesold
  );

  const getLanguage = () => {
    return language;
  };

  const handleSetLanguage = (lang) => {
    setLanguage(lang);
  };
  const handleSetVideoSource = (source) => {
    setVideoSource(source);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleCanPlay = () => {
      setIsLoading(false); // Video is ready, no longer loading
      videoElement.play(); // Play the video once it's ready
    };

    const handleLoadStart = () => {
      setIsLoading(true); // Video is loading, show placeholder
    };

    if (videoElement) {
      videoElement.pause(); // Pause the video before changing the source
      videoElement.addEventListener("canplay", handleCanPlay);
      videoElement.addEventListener("loadstart", handleLoadStart);

      videoElement.load(); // Load the new video source

      // Clean up the event listeners
      return () => {
        videoElement.removeEventListener("canplay", handleCanPlay);
        videoElement.removeEventListener("loadstart", handleLoadStart);
      };
    }
  }, [videoSource]);

  function setRealVh() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // 頁面加載時設置
  window.addEventListener("load", setRealVh);

  // 窗口大小改變時設置
  window.addEventListener("resize", setRealVh);

  // 包装并发送消息的函数
  function sendMessageToIframe(message) {
    // 解析传入的 message 数据
    const parsedData = JSON.parse(message.data).data;

    // 获取 iframe 元素
    const iframe = document.getElementById("myIframe");

    let webviewURL = process.env.REACT_APP_WEBVIEW_URL;
    if (webviewURL === "" || webviewURL == null) {
      webviewURL = "https://webview.ssos.dev.thebarkingdog.tw/";
    }

    if (iframe) {
      iframe.contentWindow.postMessage(parsedData, webviewURL);
    }
  }

  // 模拟接收 window.postMessage 的事件监听器
  window.addEventListener("message", function (event) {
    // 检查消息类型是否为 "__wbview_stateEventHandler"
    if (
      event.data.type === "__wbview_stateEventHandler" &&
      event.data.source === "native-mock"
    ) {
      sendMessageToIframe(event.data);
    }
  });

  // 定義監聽和更新樣式的函數
  const checkAspectRatio = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const aspectRatio = width / height;

    if (aspectRatio > 1) {
      setStyles(landscapeStyles); // 應用橫向樣式
    } else {
      setStyles(stylesold); // 應用直向樣式
    }
  };

  // 監聽視窗大小變化，動態更新樣式
  useEffect(() => {
    window.addEventListener("resize", checkAspectRatio);
    // 在組件卸載時移除監聽器
    return () => {
      window.removeEventListener("resize", checkAspectRatio);
    };
  }, []);

  return (
    <>
      {" "}
      {styles === landscapeStyles ? (
        <Landscape
          styles={styles}
          videoRef={videoRef}
          videoSource={videoSource}
          getLanguage={getLanguage}
          handleSetLanguage={handleSetLanguage}
          handleSetVideoSource={handleSetVideoSource}
          token={token}
          webviewURL={webviewURL}
        />
      ) : (
        <Portrait
          styles={styles}
          videoRef={videoRef}
          videoSource={videoSource}
          getLanguage={getLanguage}
          handleSetLanguage={handleSetLanguage}
          handleSetVideoSource={handleSetVideoSource}
          token={token}
          webviewURL={webviewURL}
        />
      )}
    </>
  );
}

export default MediaPage;
