const stylesold = {
  videoContainer: {
    position: "relative",
    flex: 1,
    backgroundColor: "#000",
    height: "33vh",
    display: "flex",
    alignItems: "center",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: 1, // 確保視頻在最底層
  },
  placeholder: {
    position: "absolute", // 使圖片絕對定位於容器中
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0, // 確保圖片在影片上方
  },
  placeholderImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // 確保圖片填充容器並保持比例
  },
  iframeContainer: {
    flex: 2,
    height: "67vh",
    borderTop: "1px solid #ccc",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
  recordingIndicator: {
    position: "absolute",
    bottom: "0",
    left: "50%", // 移動到父容器的50%
    transform: "translateX(-50%)", // 水平居中
    width: "70%",
    height: "60px",
    backgroundColor: "rgba(224, 247, 250, 0.8)", // 輕藍色背景
    borderRadius: "30px", // 圓角效果
    overflow: "hidden",
    zIndex: 4,
    display: "flex",
    flexDirection: "column", // 垂直排列
    justifyContent: "center", // 中心對齊
    alignItems: "center", // 垂直居中
  },
  recordingIndicatorInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: "5px", // 讓動畫和文字之間有些間隔
  },
  bar: {
    width: "4px",
    backgroundColor: "blue",
    display: "inline-block",
    marginRight: "2px",
    transition: "height 0.1s ease-in-out", // 平滑過渡效果
  },
  fullscreenButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: 2, // 確保按鈕位於頂層
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent", // 完全透明
    zIndex: 2, // 使覆蓋層在視頻之上
  },
  voiceButton: {
    position: "absolute",
    right: "20px",
    backgroundColor: "#FF6347",
    border: "none",
    color: "#FFF",
    padding: "15px",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "24px",
    width: "60px", // 设置固定宽度
    height: "60px", // 设置固定高度
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3, // 使按钮在覆盖层之上
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  },
  voiceButtonHover: {
    transform: "scale(1.1)", // 鼠标悬停时放大
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)", // 增强阴影效果
    backgroundColor: "#FF4500", // 更深的红色背景
  },
  voiceButtonDisabled: {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
    boxShadow: "none",
  },
  spinner: {
    width: "24px", // 保持与按钮内部图标一致的大小
    height: "24px", // 保持与按钮内部图标一致的大小
    border: "4px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
    borderTop: "4px solid #fff",
    animation: "spin 1s linear infinite",
  },
  questionOverlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "rgba(44, 62, 80, 0.8)", // 半透明背景
    color: "#ecf0f1",
    padding: "10px 20px",
    boxSizing: "border-box",
    fontSize: "18px",
    lineHeight: "1.5",
    whiteSpace: "normal", // 設置為 normal 以允許換行
    overflow: "hidden",
    textOverflow: "ellipsis",
    zIndex: 3, // 使問題覆蓋在視頻和覆蓋層之上
    wordWrap: "break-word", // 讓長單詞換行
  },

  languageSwitcher: {
    position: "absolute",
    top: "10px", // 移到與全螢幕按鈕同樣的 top
    right: "60px", // 調整 right 使按鈕不重疊
    display: "flex",
    gap: "10px",
    zIndex: 3, // 使語言切換器在覆蓋層之上
  },
  languageButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  languageButtonActive: {
    backgroundColor: "#0056b3",
  },
};

export { stylesold };
