/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import {
  EventStreamContentType,
  fetchEventSource,
} from "@microsoft/fetch-event-source";

let abortController = null;
const AudioRecorder = ({ styles, language, setVideoSource, token }) => {
  const [audioContext, setAudioContext] = useState(null);
  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [userQuestion, setUserQuestion] = useState("");
  const [isPauseAudio, setIsPauseAudio] = useState(false);
  const [resetAudio, setResetAudio] = useState(false);
  const [state, setState] = useState({
    messages: [],
    audioArr: [],
    isLoading: false,
    amazeKey: "",
    apiKey: "",
    chatAmzAccessToken: "",
    characterPrompt: [],
  });

  const messageDefaults = { language: "en-US" };

  useEffect(() => handleResetAudio(), [resetAudio]);

  useEffect(() => {
    if (isPauseAudio) handlePauseAudio();
  }, [isPauseAudio]);

  useEffect(() => {
    if (!isPauseAudio && currentAudioIndex < state.audioArr.length) {
      console.log(currentAudioIndex);
      playAudio();
    }
  }, [state.audioArr, currentAudioIndex, isPauseAudio]);
  const handleMouseEnter = () => {
    if (!isButtonDisabled) setIsHovered(true);
  };

  const handleMouseLeave = () => setIsHovered(false);

  const handleResetAudio = () => {
    if (!resetAudio) return;
    handlePauseAudio();
    setState((prevState) => ({
      ...prevState,
      audioArr: [],
    }));
    setCurrentAudioIndex(0);
    setUserQuestion("");
    setVideoSource("idle.mp4");
    setResetAudio(false);
  };

  const handlePauseAudio = () => {
    state.audioArr.forEach((audio) => {
      audio.pause();
      audio.currentTime = 0;
    });
    setResetAudio(true);
  };

  const playAudio = () => {
    if (currentAudioIndex < state.audioArr.length) {
      const currentAudio = state.audioArr[currentAudioIndex];

      // 清除以前的 onended 事件，以防止意外重複觸發
      currentAudio.onended = null;

      currentAudio.muted = true;
      // currentAudio.oncanplaythrough = () => {
      //   currentAudio.muted = false;
      // };
      currentAudio.onended = () => {
        if (
          currentAudioIndex !== 0 &&
          currentAudioIndex === state.audioArr.length - 1
        ) {
          if (userQuestion.includes("?") || userQuestion.includes("？")) {
            onMicClick();
          }
          resetAudioPlayback();
        }
        setCurrentAudioIndex((prevIndex) => prevIndex + 1);
      };

      const playPromise = currentAudio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            currentAudio.muted = false;
          })
          .catch((error) => {
            console.error("播放音訊時發生錯誤:", error);
            // alert("音訊播放被瀏覽器阻止，請點擊頁面以允許音訊播放。");
          });
      }
    }
  };

  const stopResponseSSE = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  const resetAudioPlayback = () => {
    setUserQuestion("");
    setVideoSource("idle.mp4");
    setResetAudio(true);
  };

  const checkMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });

      if (permissionStatus.state === "granted") {
        startRecording();
      } else if (permissionStatus.state === "prompt") {
        requestMicrophoneAccess();
      } else {
        alert("您需要授予麦克风权限才能使用此功能。");
      }

      permissionStatus.onchange = () => {
        if (permissionStatus.state === "granted") {
          startRecording();
        } else {
          alert("您需要授予麦克风权限才能使用此功能。");
        }
      };
    } catch (error) {
      console.error("Error checking microphone permission:", error);
      alert("无法检测麦克风权限，请检查您的浏览器设置。");
    }
  };

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (stream) {
        startRecording();
      }
    } catch (error) {
      console.error("Microphone access denied:", error);
      alert("您需要授予麦克风权限才能使用此功能。");
    }
  };

  const onMicClick = (e) => {
    if (e) e.preventDefault();
    if (isButtonDisabled) return;

    if (isListening) {
      stopRecordingIfActive();
      clearVoice();
    } else {
      stopResponseSSE();
      setResetAudio(true);
      setIsListening(true);
      checkMicrophonePermission();
    }
  };

  const clearVoice = () => {
    setIsListening(false);
    stopRecordingIfActive();
    setMediaRecorder(null);
    setState((prevState) => ({
      ...prevState,
      audioArr: [],
      messages: [],
    }));
    setUserQuestion("");
    setVideoSource("idle.mp4");
  };

  const stopRecordingIfActive = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    if (audioContext && audioContext.state !== "closed") {
      audioContext
        .close()
        .catch((err) => console.error("Error closing AudioContext:", err));
    }
  };

  const sendMessageInStream = useCallback(
    async (input, handleAnswer, handleClose, chatApiUrl, handleVoice) => {
      stopResponseSSE();
      abortController = new AbortController(); // Reset the abortController
      const signal = abortController.signal;

      const merged = {
        ...messageDefaults,
        ...input,
        streaming: true,
      };

      fetchEventSource(chatApiUrl, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(merged),
        signal: signal,
        onopen: async (res) => {
          if (
            res.ok &&
            res.headers.get("content-type") === EventStreamContentType
          ) {
            return;
          }
          throw new Error("Error opening stream");
        },
        onmessage: async (event) => {
          if (signal?.aborted) return; // Ignore messages if the request was aborted
          handleStreamMessage(event, handleAnswer, handleVoice, handleClose);
        },
        onclose: () => {
          abortController = new AbortController();
          if (!signal?.aborted) handleStreamClose(handleAnswer, handleClose);
        },
        onerror: (err) => {
          if (!signal?.aborted) handleStreamError(err, handleClose);
        },
        openWhenHidden: true,
      });
    },
    [abortController, token, messageDefaults]
  );

  const handleStreamMessage = (
    event,
    handleAnswer,
    handleVoice,
    handleClose
  ) => {
    if (event?.event === "data") {
      setIsButtonDisabled(false);
      const parsed = JSON.parse(event?.data);
      const chunk = parsed.data;
      handleAnswer(chunk, false);
    }

    if (event?.event === "metadata") {
      const metadata = JSON.parse(event?.data).data;
      const iframe = document.getElementById("myIframe");

      if (iframe) {
        iframe.contentWindow.postMessage(
          {
            type: "__wbview_stateEventHandler",
            source: "native-mock",
            data: metadata,
          },
          "*"
        );
      }
    }

    if (event?.event === "voice") {
      setVideoSource("talk.mp4");
      const parsed = JSON.parse(event?.data);
      const chunk = parsed.data;
      handleVoice(chunk);
    }

    if (event?.event === "done") {
      handleAnswer("", true);
      handleClose();
      //abortController.abort();
    }
  };

  const handleStreamClose = (handleAnswer, handleClose) => {
    handleAnswer("", true);
    handleClose();
    //abortController.abort();
  };

  const handleStreamError = (err, handleClose) => {
    console.error(err);
    handleClose();
    //abortController.abort();
  };

  const correctTextUsingAPI = async (text, token) => {
    try {
      let apiURL = process.env.REACT_APP_API_URL;
      if (apiURL === "" || apiURL == null) {
        apiURL = "https://api.ssos.dev.thebarkingdog.tw";
      }
      const correctorResponse = await fetch(apiURL + "/api/v1/corrector", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          text: text,
          threshold: 0.8,
          enable_confuse_sim: true,
          use_tone: true,
        }),
      });

      if (correctorResponse.ok) {
        const correctorResult = await correctorResponse.json();
        return correctorResult.data.text;
      } else {
        console.error("Corrector API failed");
        return text;
      }
    } catch (error) {
      console.error("Error calling Corrector API:", error);
      return text;
    }
  };

  const handleSTT = async (audioBlob, chatConfig) => {
    setIsButtonDisabled(true);

    try {
      const audioFile = new File([audioBlob], "audio.mp3", {
        type: "audio/mp3",
      });
      const formData = createSTTFormData(audioFile, chatConfig);

      const response = await fetch("https://api.ai-amaze.com/api/v1/asr", {
        method: "POST",
        headers: {
          "api-key": "amz-r8OiHzLY54ySMumfbmArZ95Rnjw4GlVfOoxmau7qYXr",
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        processSTTResult(result, chatConfig);
      } else {
        handleError("Failed to send audio", response);
      }
    } catch (error) {
      handleError("Error while sending audio", error);
      localStorage.removeItem("chat_amz_access_token");
    }
  };

  const createSTTFormData = (audioFile, chatConfig) => {
    const formData = new FormData();
    formData.append("file", audioFile);
    formData.append("amaze_key", chatConfig.amazeKey);
    formData.append("language", convertLanguage(language));
    formData.append(
      "provider",
      ["zh-TW", "en-US", "zh", "en"].includes(convertLanguage(language))
        ? "azure"
        : "openai"
    );
    formData.append("force_encoder", "true");
    return formData;
  };

  const processSTTResult = async (result, chatConfig) => {
    if (result.data.text !== "") {
      const finalCorrectedText = await correctTextUsingAPI(
        result.data.text,
        token
      );
      const correctedText = replaceWordsUsingMap(
        finalCorrectedText,
        chatConfig.replacements
      );
      setUserQuestion(correctedText);
      handleSubmit(correctedText, chatConfig.chatApiUrl, chatConfig);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleError = (message, error) => {
    setIsButtonDisabled(false);
    console.error(message, error);
  };

  const startRecording = async () => {
    setIsPauseAudio(true);

    try {
      if (audioContext && audioContext.state !== "closed") {
        console.warn("AudioContext is already running.");
        return;
      }

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioCtx.createMediaStreamSource(audioStream);

      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 2048;
      source.connect(analyserNode);

      const recorder = new MediaRecorder(audioStream);
      let localChunksObj = { chunks: [] };
      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          localChunksObj.chunks.push(e.data);
        }
      };

      recorder.start(100);
      analyzeAudio(analyserNode, localChunksObj, audioCtx);
      setAudioContext(audioCtx);
      setStream(audioStream);
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = (localChunks) => {
    stopRecordingIfActive();
    setIsListening(false);
    stopStream();
    closeAudioContext();
    const audioBlob = new Blob(localChunks, { type: "audio/wav" });
    setIsPauseAudio(false);
    let apiURL = process.env.REACT_APP_API_URL;
    if (apiURL === "" || apiURL == null) {
      apiURL = "https://api.ssos.dev.thebarkingdog.tw";
    }
    handleSTT(audioBlob, {
      amazeKey: "a74a8ea62fcf32c6efa92d30a6f9834e",
      apiKey: "your-api-key",
      replacements: { 煎豆腐: "涓豆腐", 豆腐包: "豆腐煲" },
      chatApiUrl: apiURL + "/api/v1/app/full/chat_with_kiosk_streaming",
      language: language,
    });
  };

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  const closeAudioContext = () => {
    if (audioContext && audioContext.state !== "closed") {
      audioContext.close().catch((err) => {
        console.error("Error closing AudioContext:", err);
      });
    }
  };

  const analyzeAudio = (analyserNode, localChunksObj, audioCtx) => {
    const bufferLength = analyserNode.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    let silenceDuration = 0;
    let totalDuration = 0;
    let recordingStarted = false;
    const maxSilenceDuration = 4000;
    const voiceThreshold = 0.01;
    const maxDuration = 50000;
    const triggerVoiceThreshold = 0.03;

    const analyze = () => {
      analyserNode.getByteTimeDomainData(dataArray);
      const volume = calculateVolume(dataArray, bufferLength);
      totalDuration += 100;
      if (volume >= triggerVoiceThreshold) recordingStarted = true;
      if (volume < voiceThreshold && recordingStarted) silenceDuration += 100;
      else silenceDuration = 0;

      if (
        (silenceDuration >= maxSilenceDuration && recordingStarted) ||
        totalDuration > maxDuration
      ) {
        stopRecording(localChunksObj.chunks);
        closeAudioContextIfNecessary(audioCtx);
        recordingStarted = false;
        return;
      } else {
        requestAnimationFrame(analyze);
      }

      updateVolumeBars(volume, recordingStarted);
    };

    analyze();
  };

  const calculateVolume = (dataArray, bufferLength) => {
    let sum = 0;
    for (let i = 0; i < bufferLength; i++) {
      const value = (dataArray[i] - 128) / 128.0;
      sum += value * value;
    }
    return Math.sqrt(sum / bufferLength);
  };

  const closeAudioContextIfNecessary = (audioCtx) => {
    if (audioCtx.state !== "closed") {
      audioCtx.close().catch((err) => {
        console.error("Error closing AudioContext:", err);
      });
    }
  };

  const volumeHistory = new Array(50).fill(0);
  const updateVolumeBars = (volume, recordingStarted) => {
    volumeHistory.push(volume);
    if (volumeHistory.length > 50) volumeHistory.shift();

    const bars = document.querySelectorAll(".bar");
    bars.forEach((bar, index) => {
      const barHeight = Math.min(0 + volumeHistory[index] * 300, 50);
      bar.style.height = `${barHeight}px`;
      bar.style.backgroundColor =
        volume < 0.01 && recordingStarted ? "red" : "#007bff";
    });
  };

  const handleSubmit = async (msg, chatApiUrl, chatConfig) => {
    appendMessage(
      {
        body: msg,
        sender: MessageSender.User,
        timestamp: new Date().toISOString(),
        type: "text",
      },
      { isDone: true, isNew: true }
    );

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let answer = "";
    let isNew = true;

    const inputConfig = {
      message: msg,
      language: language(),
    };

    const handleAnswer = (str, isDone) => {
      if (str === "" && answer === "") return;
      answer += str;
      setUserQuestion(answer);
      appendMessage(
        {
          sender: MessageSender.System,
          body: answer,
          timestamp: new Date().toISOString(),
          type: "text",
        },
        { isDone, isNew }
      );
      isNew = false;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    };

    const handleClose = () => {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    };

    const handleVoice = (str) => appendVoice(str);

    sendMessageInStream(
      inputConfig,
      handleAnswer,
      handleClose,
      chatApiUrl,
      handleVoice,
      state.chatAmzAccessToken
    );
  };

  const appendMessage = (message, msgConfig) => {
    setState((prevState) => {
      if (!msgConfig.isNew && !msgConfig.isDone) {
        const updatedMessages = updateLastMessage(
          prevState.messages,
          message.body
        );
        return { ...prevState, messages: updatedMessages };
      }
      if (msgConfig.isNew) {
        return { ...prevState, messages: [...prevState.messages, message] };
      }
      return prevState;
    });
  };

  const updateLastMessage = (messages, newBody) => {
    const lastThemIdx = messages.findLastIndex(
      (item) => item.sender === MessageSender.System && item.type === "text"
    );
    const updatedMessages = [...messages];
    updatedMessages[lastThemIdx] = {
      ...updatedMessages[lastThemIdx],
      body: newBody,
    };
    return updatedMessages;
  };

  const appendVoice = (voiceStr) => {
    if (!voiceStr) return;
    const audioElement = createAudioElementFromVoiceStr(voiceStr);

    setState((prevState) => ({
      ...prevState,
      audioArr: [...prevState.audioArr, audioElement],
    }));

    // 如果目前沒有正在播放的音訊，開始播放新增的音訊
    if (state.audioArr.length === 0) {
      playAudio();
    }
  };

  const createAudioElementFromVoiceStr = (voiceStr) => {
    const byteArray = Uint8Array.from(atob(voiceStr), (char) =>
      char.charCodeAt(0)
    );
    const blob = new Blob([byteArray], { type: "audio/mp3" });
    const audioBlob = URL.createObjectURL(blob);
    return new Audio(audioBlob);
  };

  const convertLanguage = (language) => {
    const langMap = {
      "zh-TW": "zh",
      "zh-CN": "zh",
      "en-US": "en",
      "vi-VN": "vi",
      "th-TH": "th",
      "id-ID": "id",
      "ms-MY": "ms",
      "ja-JP": "ja",
    };
    return langMap[language] || "zh";
  };

  const replaceWord = (text, targetWord, replaceWord) => {
    const regex = new RegExp(targetWord, "g");
    return text.replace(regex, replaceWord);
  };

  const replaceWordsUsingMap = (text, replacements) => {
    for (let targetWord in replacements) {
      if (replacements.hasOwnProperty(targetWord)) {
        text = replaceWord(text, targetWord, replacements[targetWord]);
      }
    }
    return text;
  };

  const MessageSender = {
    User: "User",
    System: "System",
  };

  return (
    <div>
      <p>{currentAudioIndex}</p>
      <button
        style={{
          ...styles.voiceButton,
          ...(isButtonDisabled ? styles.voiceButtonDisabled : {}),
          ...(isHovered && !isButtonDisabled ? styles.voiceButtonHover : {}),
          ...(isListening ? styles.voiceButtonActive : {}),
        }}
        onClick={onMicClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label="Start or stop voice recording"
        disabled={isButtonDisabled}
      >
        {isButtonDisabled ? (
          <div style={styles.spinner}></div>
        ) : isListening ? (
          <FaMicrophoneSlash />
        ) : (
          <FaMicrophone />
        )}
      </button>

      {isListening && (
        <div style={styles.recordingIndicator}>
          <div style={styles.recordingIndicatorInner}>
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="bar"
                style={{
                  ...styles.bar,
                  height: "5px",
                  animationDelay: `${i * 0.1}s`,
                }}
              ></div>
            ))}
          </div>
          <div style={styles.recordingText}>
            <span style={styles.ellipsis}></span>
          </div>
        </div>
      )}
      {userQuestion && <div style={styles.questionOverlay}>{userQuestion}</div>}
    </div>
  );
};

export default AudioRecorder;
