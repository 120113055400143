const landscapeStyles = {
  container: {
    display: "flex", // 使用 Flexbox 布局
    flexDirection: "row", // 設置水平排列
    height: "100vh", // 讓容器佔滿整個螢幕高度
  },
  videoContainer: {
    position: "relative",
    flex: 0.33, // 寬度佔1/3
    height: "100%", // 高度佔滿整個容器
    backgroundColor: "#000",
    display: "flex",
    alignItems: "center", // 垂直方向居中
    justifyContent: "center", // 水平方向居中
    overflow: "hidden", // 確保超出容器的部分被隱藏
  },
  video: {
    height: "100%", // 影片高度佔滿容器
    width: "auto", // 寬度自動調整，保持比例
    objectFit: "contain", // 確保影片內容完全顯示
    zIndex: 1, // 確保視頻在最底層
  },
  placeholder: {
    position: "absolute", // 使圖片絕對定位於容器中
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0, // 確保圖片在影片上方
  },
  placeholderImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // 確保圖片填充容器並保持比例
  },
  iframeContainer: {
    flex: 0.67, // iframe佔整體畫面的2/3寬度
    height: "100%", // 高度佔滿整個容器
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid #ccc", // 影片與內嵌框架之間的分隔線
  },
  iframe: {
    width: "100%", // iframe 寬度填滿容器
    height: "100%", // iframe 高度填滿容器
    border: "none", // 移除內嵌框架的邊框
  },
  recordingIndicator: {
    position: "absolute",
    bottom: "15vh",
    left: "50%", // 移動到父容器的50%
    transform: "translateX(-50%)", // 水平居中
    width: "70%",
    height: "60px",
    backgroundColor: "rgba(224, 247, 250, 0.8)", // 輕藍色背景
    borderRadius: "30px", // 圓角效果
    overflow: "hidden",
    zIndex: 4,
    display: "flex",
    flexDirection: "column", // 垂直排列
    justifyContent: "center", // 中心對齊
    alignItems: "center", // 垂直居中
  },
  recordingIndicatorInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: "5px", // 讓動畫和文字之間有些間隔
  },
  bar: {
    width: "4px",
    backgroundColor: "#007bff", // 藍色條
    display: "inline-block",
    marginRight: "2px",
    transition: "height 0.1s ease-in-out", // 平滑過渡效果
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent", // 完全透明
    zIndex: 2, // 使覆蓋層在視頻之上
  },
  voiceButton: {
    position: "absolute",
    bottom: "5vh", // 設置按鈕距離底部10px
    left: "calc(50% - 25px)",
    backgroundColor: "#FF6347",
    border: "none",
    color: "#FFF",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "24px",
    width: "15vw", // 使用視口寬度來設置按鈕大小
    height: "15vw", // 使用視口寬度來設置按鈕大小，保持比例
    minWidth: "50px", // 設置最小寬度
    minHeight: "50px", // 設置最小高度
    maxWidth: "90px", // 設置最大寬度
    maxHeight: "90px", // 設置最大高度
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 5, // 使按鈕在覆蓋層之上
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  },
  voiceButtonHover: {
    transform: "scale(1.1)", // 鼠标悬停时放大
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)", // 增强阴影效果
    backgroundColor: "#FF4500", // 更深的红色背景
  },
  voiceButtonDisabled: {
    backgroundColor: "#ccc",
    cursor: "not-allowed",
    boxShadow: "none",
  },
  spinner: {
    width: "24px", // 保持与按钮内部图标一致的大小
    height: "24px", // 保持与按钮内部图标一致的大小
    border: "4px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
    borderTop: "4px solid #fff",
    animation: "spin 1s linear infinite",
  },
  questionOverlay: {
    position: "absolute",
    bottom: "15vh",
    left: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "rgba(44, 62, 80, 0.8)", // 半透明背景
    color: "#ecf0f1",
    padding: "10px 20px",
    boxSizing: "border-box",
    fontSize: "18px",
    lineHeight: "1.5",
    whiteSpace: "normal", // 設置為 normal 以允許換行
    overflow: "hidden",
    textOverflow: "ellipsis",
    zIndex: 3, // 使問題覆蓋在視頻和覆蓋層之上
    wordWrap: "break-word", // 讓長單詞換行
  },

  fullscreenButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: 2, // 確保按鈕位於頂層
  },
  languageSwitcher: {
    position: "absolute",
    top: "10px", // 移到與全螢幕按鈕同樣的 top
    right: "60px", // 調整 right 使按鈕不重疊
    display: "flex",
    gap: "10px",
    zIndex: 3, // 使語言切換器在覆蓋層之上
  },
  languageButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  languageButtonActive: {
    backgroundColor: "#0056b3",
  },
};

export { landscapeStyles };
